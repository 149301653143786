<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="font-weight-light display-1">Contact Us</h1>
              <h3 class="font-weight-light mt-3">
                We’re here to assist with any questions or feedback you may have. Fill out the form, and our team
                will respond promptly. Please provide detailed information to help us serve you better. Thank you for
                contacting us!
              </h3>
              <h3 class="font-weight-light mt-3">
                Telephone: +1 (876) 207-3344
              </h3>
              <h3 class="font-weight-light">
                Email: ournationexpress@gmail.com
              </h3>
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field v-model="name" :rules="nameRules" label="Name" required></v-text-field>

                <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>

                <v-textarea v-model="textArea" :rules="textAreaRules" label="Message" required />

                <v-btn :disabled="!valid" color="primary" :dark="valid" rounded block class="mt-3" @click="submit">
                  Send
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.png" />
    </div>
    <v-snackbar v-model="snackbar.enabled" timeout="3000" right top :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.enabled = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
</style>

<script>
import { Feedback } from '@/models/feedback';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "The name field is required",
      (v) => (v && v.length >= 6) || "The name must be longer than 6 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "The email field is required",
      (v) => /.+@.+\..+/.test(v) || "The email must be valid",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "The text field is required",
      (v) => (v && v.length >= 10) || "Minimum of 10 characters",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    },
    feedback: new Feedback()
  }),
  methods: {
    ...mapActions({
      createFeedback: 'feedback/createFeedback',
    }),
    async submit() {
      this.feedback.name = this.name
      this.feedback.email = this.email
      this.feedback.message = this.textArea
      await this.createFeedback(
        this.feedback
      ).then(() => {
        this.snackbar.text = "Message sent successfully"
        this.snackbar.color = "success"
        this.snackbar.enabled = true
      }).catch(() => {
        this.snackbar.text = "Error sending message"
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      })
    }
  }
};
</script>
